import { CurrencyPipe, NgClass, NgFor, NgIf, SlicePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CheckboxComponent, RadioComponent, RadioGroupComponent } from '@dougs/ds';
import { ConnectionAccount } from '@dougs/synchronized-accounts/dto';

@Component({
  selector: 'dougs-connection-accounts-selection',
  templateUrl: './connection-accounts-selection.component.html',
  styleUrls: ['./connection-accounts-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    RadioGroupComponent,
    FormsModule,
    NgFor,
    CheckboxComponent,
    RadioComponent,
    NgClass,
    SlicePipe,
    CurrencyPipe,
  ],
})
export class ConnectionAccountsSelectionComponent {
  private _connectionAccounts!: ConnectionAccount[];

  @Input()
  get connectionAccounts() {
    return this._connectionAccounts;
  }
  set connectionAccounts(connectionAccounts) {
    this._connectionAccounts = connectionAccounts;
    this.availableConnectionAccounts = [];
    this.synchronizedConnectionAccounts = [];
    this.deletedConnectionAccounts = [];

    for (const connectionAccount of connectionAccounts) {
      if (connectionAccount.deletedAt) {
        this.deletedConnectionAccounts.push(connectionAccount);
      } else if (connectionAccount.synchronized) {
        this.synchronizedConnectionAccounts.push(connectionAccount);
      } else {
        this.availableConnectionAccounts.push(connectionAccount);
      }
    }
  }

  @Input() multipleSelection = true;
  @Output() selectConnectionAccount: EventEmitter<ConnectionAccount[]> = new EventEmitter<ConnectionAccount[]>();

  selectedConnectionAccounts: ConnectionAccount[] = [];
  availableConnectionAccounts!: ConnectionAccount[];
  synchronizedConnectionAccounts!: ConnectionAccount[];
  deletedConnectionAccounts!: ConnectionAccount[];

  showAllAvailableConnectionAccounts = false;
  showAllSynchronizedConnectionAccounts = false;
  showAllDeletedConnectionAccounts = false;

  onCheck(checked: boolean, connectionAccount: ConnectionAccount): void {
    if (checked) {
      this.selectedConnectionAccounts = [...this.selectedConnectionAccounts, connectionAccount];
    } else {
      this.selectedConnectionAccounts = this.selectedConnectionAccounts.filter(
        (connectionAccountIterated) => connectionAccountIterated.id !== connectionAccount.id,
      );
    }

    this.selectConnectionAccount.emit(this.selectedConnectionAccounts);
  }

  onSelect(connectionAccount: ConnectionAccount) {
    this.selectedConnectionAccounts = [connectionAccount];
    this.selectConnectionAccount.emit(this.selectedConnectionAccounts);
  }
}
