import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MetricsService } from '@dougs/core/metrics';
import { URL } from '@dougs/core/routing';
import {
  ButtonComponent,
  MODAL_DATA,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
} from '@dougs/ds';
import { SynchronizedAccount } from '@dougs/synchronized-accounts/dto';
import { MergeData } from '@dougs/task/dto';
import { UserStateService } from '@dougs/user/shared';
import { AddConnectionLoginComponent } from '../../components/add-connection-login/add-connection-login.component';
import { AddConnectionService } from '../../services/add-connection.service';

export interface AddConnectionModalData {
  synchronizedAccount?: SynchronizedAccount;
  mergeData?: MergeData;
  metadata?: {
    /**
     * Location from which this modal has been called
     */
    location?: 'Settings' | 'Performance' | 'Accounting Demo Mode On' | 'Accounting Demo Mode Off' | 'unknown';
  };
}

@Component({
  selector: 'dougs-add-connection-modal',
  templateUrl: './add-connection-modal.component.html',
  styleUrls: ['./add-connection-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AddConnectionService],
  standalone: true,
  imports: [
    FormsModule,
    ModalTitleDirective,
    NgClass,
    ModalContentDirective,
    AddConnectionLoginComponent,
    ModalFooterDirective,
    ButtonComponent,
    NgIf,
  ],
})
export class AddConnectionModalComponent implements OnInit {
  pending = false;

  constructor(
    public readonly addConnectionService: AddConnectionService,
    private readonly cdr: ChangeDetectorRef,
    private readonly modalRef: ModalRef,
    private readonly metricServices: MetricsService,
    public readonly userStateService: UserStateService,
    @Inject(MODAL_DATA)
    public data?: AddConnectionModalData,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.addConnectionService.getConnectionFromMergeData(this.data?.mergeData);

    if (this.data?.metadata?.location) {
      this.metricServices.pushMixpanelEvent('Bank Account Synchronization Initialized', {
        'CTA Location': this.data?.metadata?.location,
      });
    }

    this.cdr.markForCheck();
  }

  async onSubmit(): Promise<void> {
    if (this.canSubmit() && this.addConnectionService.selectedSource) {
      if (this.data?.metadata?.location) {
        this.metricServices.pushMixpanelEvent('Accounting Bank Synchronization CTA Confirmed', {
          source: this.convertLocationToEventProperty(this.data.metadata.location),
        });
      }

      let shouldCloseModal: boolean;
      this.pending = true;

      if (this.addConnectionService.selectedConnectionAccounts.length) {
        if (this.addConnectionService.providerToMerge) {
          shouldCloseModal = await this.addConnectionService.mergeProvider();
        } else {
          shouldCloseModal = await this.addConnectionService.createProviders(this.data?.synchronizedAccount);
        }
      } else if (this.addConnectionService.connectionToMerge) {
        shouldCloseModal = await this.addConnectionService.mergeConnection();
      } else {
        shouldCloseModal = await this.addConnectionService.addConnection(this.data?.synchronizedAccount);
      }

      if (shouldCloseModal && this.data?.metadata?.location) {
        this.metricServices.pushMixpanelEvent('Bank Account Synchronized');
        this.metricServices.pushMixpanelEvent('Accounting Bank Synchronization CTA Finished', {
          source: this.convertLocationToEventProperty(this.data.metadata.location),
        });
      }

      this.pending = false;

      if (shouldCloseModal) {
        this.close();
      }

      this.cdr.markForCheck();
    }
  }

  private convertLocationToEventProperty(location: string): string {
    location = location.toLowerCase();

    return [URL.ACCOUNTING as string, URL.PERFORMANCE as string].includes(location)
      ? `blankslate ${location}`
      : location;
  }

  canSubmit(): boolean {
    if (!this.addConnectionService.fieldsIsValid()) {
      return false;
    }

    if (
      this.pending ||
      !this.addConnectionService.connectionAccountReady ||
      !this.addConnectionService.selectedSource
    ) {
      return false;
    }

    return !(
      !this.addConnectionService.createNewConnection &&
      this.addConnectionService.connectionAccounts.length > 0 &&
      !this.addConnectionService.selectedConnectionAccounts.length
    );
  }

  close(): void {
    if (!this.pending) {
      this.modalRef.close();
    }
  }
}
